/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React from "react";
//Custom libraries
import { OrderItemTable } from "../../widgets/order-item-table";
import FormattedPriceDisplay from "../../widgets/formatted-price-display";
//UI
import { Divider } from "@material-ui/core";
import {
  StyledBox,
  StyledButton,
  StyledGrid,
  StyledPaper,
  StyledTypography,
} from "@hcl-commerce-store-sdk/react-component";

interface MiniCartContentProps {
  title: string;
  orderType: string;
  orderItems: any[];
  subtotalLabel: string;
  subtotal: number | null;
  subtotalCurrency: string;
  hasStipend: boolean;
  stipendShowFullPrice: boolean | null;
  emptyCartMsg: string;
  cartLinkLabel: string;
  checkoutLinkLabel: string;
  handleCartOnClick: (e?: any) => void;
  handleCheckoutOnClick: (e?: any) => void;
  handleClose: (e?: any) => void;
  isOrderOwner: boolean;
  isB2B: boolean;
  handleClickStipendDialogOpen: (e?: any) => void;
  isLoggedIn: boolean;
  isStipendUser: boolean;
}

const StyledMiniCartContent = React.forwardRef<any, MiniCartContentProps>(
  (props: any, ref: any) => {
    const {
      title,
      orderType,
      orderItems,
      subtotalLabel,
      subtotal,
      subtotalCurrency,
      hasStipend,
      stipendShowFullPrice,
      emptyCartMsg,
      cartLinkLabel,
      checkoutLinkLabel,
      handleCartOnClick,
      handleCheckoutOnClick,
      handleClose,
      isOrderOwner,
      isB2B,
      handleClickStipendDialogOpen,
      isLoggedIn,
      isStipendUser,
    } = props;

    return (
      <StyledPaper
        ref={ref}
        className="mini-cart-container"
        data-testid="mini-cart-popper">
        {!isB2B ? (
          <StyledTypography
            variant="h6"
            className="horizontal-padding-2 top-padding-2">
            {title}
          </StyledTypography>
        ) : (
          <>
            <StyledTypography
              variant="h6"
              style={{ overflowWrap: "break-word" }}
              className="horizontal-padding-2 vertical-padding-2">
              {title}
            </StyledTypography>
            {/*<StyledTypography
              variant="body1"
              className="horizontal-padding-2 bottom-padding-2">
              {orderType}
        </StyledTypography>*/}
          </>
        )}
        <Divider className="heading" />
        {orderItems.length > 0 ? (
          <>
            <StyledBox className="horizontal-padding-2">
              <OrderItemTable
                data={orderItems}
                miniCartView={true}
                handleMiniCartClose={handleClose}
              />
            </StyledBox>
            <Divider />
            <StyledGrid
              container
              className="horizontal-padding-2 vertical-padding-2">
              <StyledGrid item xs={7}>
                <StyledTypography variant="body1">
                  {subtotalLabel}
                </StyledTypography>
              </StyledGrid>
              <StyledGrid item xs={5}>
                <StyledTypography variant="body1" align="right">
                  {hasStipend && !stipendShowFullPrice && <>Stipend + </>}
                  <FormattedPriceDisplay
                    min={subtotal}
                    currency={subtotalCurrency}
                  />
                </StyledTypography>
              </StyledGrid>
            </StyledGrid>
          </>
        ) : (
          <StyledTypography
            align="center"
            className="horizontal-padding-2 vertical-padding-10">
            {emptyCartMsg}
          </StyledTypography>
        )}
        <Divider />
        <StyledBox
          className="horizontal-padding-2 vertical-padding-1"
          align="center">
          {isB2B && !isOrderOwner ? (
            <StyledButton
              testId="handle-cart-on-click"
              color="primary"
              onClick={handleCartOnClick}>
              {cartLinkLabel}
            </StyledButton>
          ) : (
            <>
              <StyledButton
                testId="handle-cart-on-click"
                color="primary"
                onClick={handleCartOnClick}>
                {cartLinkLabel}
              </StyledButton>
              {/* <StyledButton
                testId="handle-checkout-on-click"
                color="primary"
                disabled={!orderItems || orderItems.length <= 0}
                onClick={handleCheckoutOnClick}>
                {checkoutLinkLabel}
              </StyledButton> */}
            </>
          )}
        </StyledBox>
        {isLoggedIn && isStipendUser && (
          <StyledBox
            className="horizontal-padding-2 vertical-padding-1"
            align="center">
            <>
              <StyledButton
                testId="handle-continue-shopping-click"
                color="secondary"
                onClick={handleClickStipendDialogOpen}>
                Continue Shopping
              </StyledButton>
            </>
          </StyledBox>
        )}
      </StyledPaper>
    );
  }
);

export default StyledMiniCartContent;
