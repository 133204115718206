/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";

import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Axios, { Canceler } from "axios";
import getDisplayName from "react-display-name";
import { paramCase } from "change-case";
import { filter } from "lodash-es";

//Foundation libraries
import { useSite } from "../../_foundation/hooks/useSite";
import categoryService from "../../_foundation/apis/search/categories.service";
import personService from "../../_foundation/apis/transaction/person.service";
import eSpotService from "../../_foundation/apis/transaction/eSpot.service";
import { LOCALE } from "../../_foundation/constants/common";
import { localStorageUtil } from "../../_foundation/utils/storageUtil";

//Custom libraries
import { headerConfig } from "./headerConstant";
import { TOP_CATEGORIES_DEPTH_LIMIT } from "../../configs/catalog";
import { MINICART_CONFIGS } from "../../configs/order";
import * as ROUTES from "../../constants/routes";
import ContentRecommendationWidget from "../commerce-widgets/content-recommendation-widget";
import MiniCart from "./MiniCart";
//import LanguageToggle from "./LanguageToggle";
import MegaMenu from "./MegaMenu";
import ExpandedMenu from "./ExpandedMenu";
import ContentMenu from "./ContentMenu";
import { SearchBar } from "../widgets/search-bar";
import AccountPopperContent from "./AccountPopperContent";
import { StipendContractsDialog } from "../widgets/stipend-contracts-modal";

//Redux
import {
  userNameSelector,
  loginStatusSelector,
  userStipendSelector,
  userStipendContractSelector,
  userStipendDefaultContractIdSelector,
  stipendUserSelector,
  forUserIdSelector,
} from "../../redux/selectors/user";
import { addressDetailsSelector } from "../../redux/selectors/account";
import { ORG_SWITCH_ACTION } from "../../redux/actions/organization";
import { CONTRACT_SWITCH_ACTION } from "../../redux/actions/contract";
import { LOGOUT_REQUESTED_ACTION } from "../../redux/actions/user";
import { UPDATE_CATEGORIES_STATE_ACTION } from "../../redux/actions/category";
import { currentContractIdSelector } from "../../redux/selectors/contract";
import { successSelector } from "../../redux/selectors/success";
import { SuccessMessageReducerState } from "../../redux/reducers/reducerStateInterface";
//UI
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { ClickAwayListener } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import StoreIcon from "@material-ui/icons/Store";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Hidden } from "@material-ui/core";
import {
  StyledAccountPopper,
  StyledButton,
  StyledContainer,
  StyledHeader,
  StyledHeaderActions,
  StyledTypography,
  StyledSwipeableDrawer,
  StyledGrid,
  StyledPaper,
  StyledBox,
  PersonIcon,
  StyledList,
} from "@hcl-commerce-store-sdk/react-component";
import { useWinDimsInEM } from "../../_foundation/hooks/use-win-dims-in-em";
import {
  selectedSellersSelector,
  sellersSelector,
} from "../../redux/selectors/sellers";
import { SELLER_STORAGE_KEY } from "../../constants/common";
import { SET_SELLER_ACTION } from "../../redux/actions/sellers";
import { Sellers } from "./sellers";
import contentEspotUtil from "../../utils/_dival/contentEspotUtil";
import selectorImageWhite from "../widgets/stipend-contracts-modal/stipendContractimages/stipend selector-white.png";

interface HeaderProps {
  loggedIn: boolean;
}

const Buttonify = ({ children, ...props }) => {
  const { testId } = props;
  return (
    <StyledButton
      testId={testId}
      className="header-actionsButton"
      variant="text"
      color="secondary"
      {...props}>
      <StyledHeaderActions>{children}</StyledHeaderActions>{" "}
    </StyledButton>
  );
};

const MarketplacePopper = ({ sellerConfig }) => {
  const btnRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const guestSellerPicker = "guestSellerPicker";
  const { t } = useTranslation();
  const { mySite } = useSite();
  const onClickAway = useCallback((e) => {
    if (e.target.localName === "body") {
      return;
    }
    setOpen(false);
  }, []);

  return !mySite?.isB2B && sellerConfig.showSellerList ? (
    <>
      <div ref={btnRef}>
        <Buttonify testId="header-mp" onClick={setOpen.bind(null, !open)}>
          <StyledBox
            display="flex"
            flexDirection="column"
            alignItems="center"
            flexWrap="wrap">
            <StoreIcon />
            <StyledTypography variant="body1" component="p">
              {t("sellers.mp")}
            </StyledTypography>
          </StyledBox>
        </Buttonify>
      </div>
      <StyledAccountPopper
        id={guestSellerPicker}
        open={open}
        anchorEl={btnRef.current}
        placement="bottom-end"
        modifiers={{
          flip: { enabled: false },
          preventOverflow: { enabled: true, boundariesElement: "scrollParent" },
          hide: { enabled: false },
        }}
        className="account-popper">
        <ClickAwayListener onClickAway={onClickAway}>
          <StyledPaper className="vertical-padding-1 horizontal-padding-1">
            <StyledList disablePadding>
              <Sellers />
            </StyledList>
          </StyledPaper>
        </ClickAwayListener>
      </StyledAccountPopper>
    </>
  ) : null;
};

/**
 * Header component
 * displays Header, Mini Cart and Mega Menu
 * @param props
 */
const Header: React.FC<HeaderProps> = (props: any) => {
  const widgetName = getDisplayName(Header);
  const { w } = useWinDimsInEM();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [showSearchBar, setShowSearchBar] = useState<boolean>(false);
  const [topCategories, setTopCategories] = useState<Array<any>>([]);
  const [myAccountPopperOpen, setMyAccountPopperOpen] =
    useState<boolean>(false);
  const [stipendOptionValue, setStipendOptionValue] = useState<string>(
    localStorageUtil.get("selectedStipend") || ""
  );
  const [stipendOptionContractId, setStipendOptionContractId] =
    useState<string>(localStorageUtil.get("selectedStipendContractId") || "");
  const [selectedContractId, setSelectedContractId] = useState<string>("");

  const myAccountElRef = useRef<HTMLButtonElement>(null);

  const [miniCartPopperOpen, setMiniCartPopperOpen] = useState<boolean>(false);
  const [stipendDialogOpen, setStipendDialogOpen] = useState<boolean>(false);
  const miniCartElRef = useRef<HTMLButtonElement>(null);

  /*const [languageTogglePopperOpen, setLanguageTogglePopperOpen] =
    useState<boolean>(false);

  const languageToggleElRef = useRef<HTMLButtonElement>(null);*/

  const { mySite } = useSite();
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();

  const addressDetails = useSelector(addressDetailsSelector);
  const storeID: string = mySite ? mySite.storeID : "";
  const catalogID: string = mySite ? mySite.catalogID : "";
  const userName = useSelector(userNameSelector);
  const stipendContractsFromState = useSelector(userStipendContractSelector);
  const [stipendContracts, setStipendContracts] = useState(
    stipendContractsFromState
  );
  const [defaultNonStipendName, setDefaultNonStipendName] = useState("");
  const [defaultNonStipendDescription, setDefaultNonStipendDescription] =
    useState("");
  const stipendDefaultContractId = useSelector(
    userStipendDefaultContractIdSelector
  );
  const isStipendUser = useSelector(stipendUserSelector) === "1";
  const forUserId = useSelector(forUserIdSelector);
  const stipendContractorElRef = useRef<HTMLButtonElement>(null);
  const userValue = useSelector(stipendUserSelector);
  const firstName = addressDetails?.firstName ?? userName?.firstName;
  const lastName = addressDetails?.lastName ?? userName?.lastName;
  const contractId = useSelector(currentContractIdSelector);
  const success: SuccessMessageReducerState = useSelector(successSelector);
  const userLoggedIn = useSelector(loginStatusSelector);
  const selectedSellers = useSelector(selectedSellersSelector);
  const sellerConfig = useSelector(sellersSelector);
  const sellers = localStorageUtil.get(SELLER_STORAGE_KEY);

  const userPreviousLoggedIn = useRef();

  const userStipend = useSelector(userStipendSelector);
  const [stipendEspotName, setStipendEspotName] = useState<string | null>(null);
  const [stipendEspot, setStipendEspot] = useState<any>(null);

  const locale = localStorageUtil.get(LOCALE);

  const isB2B = Boolean(mySite?.isB2B);
  const loggedIn = props.loggedIn;
  const isShoppingEnabled = !isB2B || (isB2B && loggedIn);

  const isTablet = !useMediaQuery(theme.breakpoints.up("md"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const myAccountPopperId = "HEADER_MY_ACCOUNT_Popper";
  const miniCartPopperId = "HEADER_MINI_CART_Popper";
  //const languageTogglePopperId = "HEADER_LANGUAGE_TOGGLE_Popper";
  const CancelToken = Axios.CancelToken;
  const cancels: Canceler[] = [];
  const payloadBase: any = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };
  const payload = {
    ...payloadBase,
  };

  const handleStipendContractsDispatch = () => {
    const storeID: string = mySite.storeID;
    const parameters: any = {
      storeId: storeID,
      ...payload,
    };
    personService
      .findPersonBySelf(parameters)
      .then((res) => {
        setStipendContracts(res?.data?.ext_stipendContracts);
        setDefaultNonStipendName(res?.data?.ext_defaultNonStipendName);
        setDefaultNonStipendDescription(res?.data?.ext_defaultNonstipendDesc);
      })
      .catch((e) => {
        setStipendContracts(stipendContractsFromState);
        console.log("Could not retrieve stipend contracts", e);
      });
  };

  useEffect(() => {
    if (loggedIn) {
      handleStipendContractsDispatch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn, stipendContractsFromState, storeID]);

  const onStipendOptionChange = (e) => {
    if (e.target.name !== "") {
      localStorageUtil.set("selectedStipend", e.target.name);
      localStorageUtil.set("selectedStipendContractId", e.target.value);
      const selectedStipendNew = filter(
        stipendContracts?.stipends,
        (r) =>
          r.contractId === e.target.value && r.stipendName === e.target.name
      );
      if (
        selectedStipendNew &&
        selectedStipendNew[0] &&
        selectedStipendNew[0].stipendId !== undefined
      ) {
        localStorageUtil.set(
          "selectedStipendId",
          selectedStipendNew[0].stipendId
        );
      } else {
        localStorageUtil.set("selectedStipendId", "");
      }
    }
    setStipendOptionValue(localStorageUtil.get("selectedStipend"));
    setStipendOptionContractId(
      localStorageUtil.get("selectedStipendContractId")
    );
  };

  const handleMyAccountClick = () => {
    setMyAccountPopperOpen(true);
    setMiniCartPopperOpen(false);
    //setLanguageTogglePopperOpen(false);
  };
  const handleMyAccountPopperClose = () => setMyAccountPopperOpen(false);

  const handleMiniCartClick = () => {
    setMiniCartPopperOpen(true);
    setMyAccountPopperOpen(false);
    //setLanguageTogglePopperOpen(false);

    setTimeout(() => {
      window.scrollTo(0, 0);
    });
    setTimeout(() => {
      if (miniCartElRef !== null && miniCartElRef.current !== null) {
        miniCartElRef.current.focus();
      }
    }, 100);
  };

  const handleMiniCartPopperClose = useCallback(
    () => setMiniCartPopperOpen(false),
    []
  );

  const handleClickStipendDialogOpen = () => {
    setStipendDialogOpen(true);
    setMiniCartPopperOpen(false);
  };

  /*const handleLanguageToggleClick = () => {
    setLanguageTogglePopperOpen(true);
    setMiniCartPopperOpen(false);
    setMyAccountPopperOpen(false);
  };
  const handleLanguageTogglePopperClose = () => {
    setLanguageTogglePopperOpen(false);
  };*/

  const handleOrgChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.persist();
    event.preventDefault();
    const orgId = event.target.value;
    dispatch(
      ORG_SWITCH_ACTION({
        query: { activeOrgId: String(orgId) },
        ...payload,
      })
    );
    navigate(ROUTES.HOME);
  };

  const handleContractChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    event.persist();
    event.preventDefault();
    const conId = event.target.value;
    dispatch(
      CONTRACT_SWITCH_ACTION({
        query: { contractId: String(conId) },
        ...payloadBase,
      })
    );
    navigate(ROUTES.HOME);
  };

  const handleLogout = (event) => {
    event.preventDefault();
    const param: any = {
      ...payload,
    };
    dispatch(LOGOUT_REQUESTED_ACTION(param));
  };

  useEffect(() => {
    if (!userLoggedIn && userPreviousLoggedIn.current) {
      setMyAccountPopperOpen(false);
      setMiniCartPopperOpen(false);
      setStipendOptionValue("");
      setStipendOptionContractId("");
      localStorageUtil.set("selectedStipend", "");
      localStorageUtil.set("selectedStipendContractId", "");
      localStorageUtil.set("selectedStipendId", "");
      navigate(ROUTES.HOME);
    }
    userPreviousLoggedIn.current = userLoggedIn;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLoggedIn]);

  const [prevContractId, setPrevContractId] = useState("");

  useEffect(() => {
    setSelectedContractId("");
    if (userValue === undefined) {
      if (!loggedIn && prevContractId !== "" && prevContractId !== contractId) {
        setSelectedContractId(contractId);
      } else if (
        !loggedIn &&
        prevContractId !== "" &&
        prevContractId === contractId
      ) {
        setSelectedContractId("");
      }
      setPrevContractId(contractId);
    } else if (userValue !== undefined) {
      if (!isStipendUser || !loggedIn) {
        setSelectedContractId(contractId);
      } else {
        if (stipendOptionContractId !== "") {
          setSelectedContractId(stipendOptionContractId);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isStipendUser,
    stipendOptionContractId,
    loggedIn,
    contractId,
    userValue,
    selectedContractId,
    prevContractId,
  ]);

  useEffect(() => {
    if (
      loggedIn &&
      stipendContracts?.stipends?.length === 1 &&
      stipendContracts?.stipends[0]?.eligible !== "0" &&
      !stipendDefaultContractId &&
      isStipendUser
    ) {
      setStipendOptionValue(stipendContracts.stipends[0].stipendName);
      setStipendOptionContractId(stipendContracts.stipends[0].contractId);
      localStorageUtil.set(
        "selectedStipend",
        stipendContracts.stipends[0].stipendName
      );
      localStorageUtil.set(
        "selectedStipendContractId",
        stipendContracts.stipends[0].contractId
      );
      localStorageUtil.set(
        "selectedStipendId",
        stipendContracts.stipends[0].stipendId
      );
    }
    if (
      loggedIn &&
      stipendContracts?.stipends?.length === 0 &&
      stipendDefaultContractId &&
      isStipendUser
    ) {
      defaultNonStipendName
        ? setStipendOptionValue(defaultNonStipendName)
        : setStipendOptionValue("Credit Card");
      setStipendOptionContractId(stipendDefaultContractId);
      defaultNonStipendName
        ? localStorageUtil.set("selectedStipend", defaultNonStipendName)
        : localStorageUtil.set("selectedStipend", "Credit Card");
      localStorageUtil.set(
        "selectedStipendContractId",
        stipendDefaultContractId
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn, isStipendUser, stipendContracts, stipendDefaultContractId]);

  useEffect(() => {
    if (loggedIn) {
      if (userValue === undefined) {
        return; // Wait until userValue is loaded
      }
    }
    if (
      mySite !== null &&
      selectedContractId !== undefined &&
      selectedContractId !== ""
    ) {
      const storeID: string = mySite.storeID;
      const parameters: any = {
        storeId: storeID,
        depthAndLimit: TOP_CATEGORIES_DEPTH_LIMIT,
        query: {
          contractId: selectedContractId,
        },
        ...payload,
      };
      categoryService
        .getV2CategoryResourcesUsingGET(parameters)
        .then((res) => {
          setTopCategories(res.data.contents);
          dispatch(UPDATE_CATEGORIES_STATE_ACTION(res.data.contents));
        })
        .catch((e) => {
          setTopCategories([]);
          dispatch(UPDATE_CATEGORIES_STATE_ACTION([]));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    mySite,
    //locale,
    //contractId,
    //selectedSellers,
    //loggedIn,
    //isStipendUser,
    //userValue,
    selectedContractId,
  ]);

  useEffect(() => {
    if (success && success.key) {
      if (MINICART_CONFIGS.itemAddSuccessMsgKeys.includes(success.key)) {
        handleMiniCartClick();
      }
    }
  }, [success]);

  useEffect(() => {
    if (userStipend?.stipendType) {
      setStipendEspotName(`StipendEligibilityType${userStipend.stipendType}`);
    } else if (userStipend?.stipendNextResetDate) {
      setStipendEspotName("StipendEligibilityDate");
    }
  }, [userStipend]);

  useEffect(() => {
    if (stipendEspotName) {
      let substitutionParams: any = null;
      if (userStipend?.stipendType) {
        if (userStipend.stipendType === 3) {
          substitutionParams = [
            {
              "[stipendX]": userStipend.stipendX,
            },
          ];
        } else {
          substitutionParams = [
            { "[stipendX]": userStipend.stipendX },
            { "[stipendN]": userStipend.stipendN },
          ];
        }
      } else if (userStipend?.stipendNextResetDate) {
        substitutionParams = [
          {
            "[stipendNextResetDate]": userStipend.stipendNextResetDate,
          },
        ];
      }
      if (substitutionParams) {
        const parameters: any = {
          storeId: storeID,
          name: stipendEspotName,
          catalogId: catalogID,
          ...payloadBase,
        };

        eSpotService
          .findByName(parameters)
          .then((res) => {
            const eSpotRoot = res.data.MarketingSpotData[0];
            const title = eSpotRoot.marketingSpotDataTitle
              ? eSpotRoot.marketingSpotDataTitle[0]
                  .marketingContentDescription[0].marketingText
              : "";
            const templates = contentEspotUtil.processMarketingSpotData(
              eSpotRoot,
              title,
              substitutionParams,
              payloadBase,
              props,
              dispatch
            );
            setStipendEspot(templates);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stipendEspotName, userStipend.stipendN]);

  useEffect(() => {
    return () => {
      cancels.forEach((cancel) => cancel());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sellerConfig.initialized) {
      if (sellers?.length && !sellerConfig.showSellerList) {
        dispatch(SET_SELLER_ACTION({ sellers: null }));
      } else if (sellers?.length && !selectedSellers?.length) {
        dispatch(SET_SELLER_ACTION({ sellers }));
      }
    }
  }, [sellerConfig]); // eslint-disable-line react-hooks/exhaustive-deps

  const crwProps = useMemo(
    () => ({
      widget: {
        id: `header-${paramCase(headerConfig.espot.eSpotName)}`,
        widgetName: "content-recommendation-widget",
        name: headerConfig.espot.eSpotName,
        properties: {
          emsName: headerConfig.espot.eSpotName,
        },
      },
      page: { name: "" },
    }),
    //Content is language sensitive, so listen to translation change to render.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );

  const handleClickOpen = () => {
    handleStipendContractsDispatch();
    setStipendDialogOpen(true);
  };

  const handleStipendClose = () => {
    if (
      localStorageUtil.get("selectedStipend") ||
      stipendOptionValue?.trim() !== "" ||
      (!stipendContracts && !stipendDefaultContractId)
    ) {
      setStipendDialogOpen(false);
    }
  };

  const GetStipendSelectorLink = () => {
    return (
      <StyledGrid item xs={12}>
        <StyledButton
          ref={stipendContractorElRef}
          variant="text"
          color="secondary"
          className="header-actionsButton"
          onClick={handleClickOpen}
          disabled={forUserId}
          testId="change-stipend-dialog-open">
          <StyledHeaderActions>
            <StyledTypography
              variant="body1"
              component="p"
              style={{
                lineHeight: "normal",
                fontStyle: "italic",
              }}>
              Stipend Selector{" "}
            </StyledTypography>
            {isDesktop && (
              <StyledTypography
                variant="body1"
                style={{
                  fontSize: "0.85238125rem",
                  lineHeight: "normal",
                }}>
                Click Here to Change{" "}
              </StyledTypography>
            )}

            <StyledTypography
              variant="body1"
              component="p"
              style={{
                lineHeight: "normal",
                color: "#f44336",
              }}>
              {localStorageUtil.get("selectedStipend")}
            </StyledTypography>
            {/*
             */}
          </StyledHeaderActions>
          <img
            src={selectorImageWhite}
            alt=""
            style={{
              width: isDesktop ? "40px" : "30px",
              height: isDesktop ? "40px" : "30px",
            }}
          />
        </StyledButton>
        <StipendContractsDialog
          stipendDialogOpen={stipendDialogOpen}
          setStipendDialogOpen={setStipendDialogOpen}
          defaultNonStipendName={defaultNonStipendName}
          defaultNonStipendDescription={defaultNonStipendDescription}
          stipendContracts={stipendContracts}
          toggleStipendOption={onStipendOptionChange}
          stipendOptionValue={stipendOptionValue}
          stipendDefaultContractId={stipendDefaultContractId}
          handleClose={handleStipendClose}
        />
      </StyledGrid>
    );
  };

  //const toggleSearchBar = () => setShowSearchBar(!showSearchBar);
  const turnOffSearchBar = () => setShowSearchBar(false);
  const turnOnSearchBar = () => setShowSearchBar(true);
  const toggleOpen = () => setOpen(!open);
  const turnOffOpen = () => setOpen(false);
  const turnOnOpen = () => setOpen(true);

  return (
    <>
      <StyledHeader>
        <StyledContainer>
          <StyledGrid
            container
            justifyContent="space-between"
            alignItems="center"
            wrap="nowrap">
            <StyledGrid item>
              <StyledGrid
                className="header-topbarSection"
                container
                alignItems="center"
                wrap="nowrap"
                spacing={2}>
                <StyledGrid item>
                  <Hidden mdUp>
                    <button
                      className="menu-hamburger"
                      data-testid="menu-hamburger-element"
                      onClick={toggleOpen}>
                      <MenuIcon />
                    </button>
                  </Hidden>
                </StyledGrid>
                {mySite != null && (
                  <StyledGrid item className="header-branding">
                    <ContentRecommendationWidget {...crwProps} />
                  </StyledGrid>
                )}
              </StyledGrid>
            </StyledGrid>
            <StyledGrid item>
              <StyledGrid
                className="header-topbarSection header-top-menu-section"
                container
                alignItems="center"
                justifyContent="flex-end">
                <Hidden smDown>
                  <StyledGrid
                    item
                    md={12}
                    className="desktop-header-content-menu">
                    <ContentMenu />
                  </StyledGrid>
                </Hidden>
                <Hidden xsDown>
                  <StyledGrid container className="header-phone-number">
                    <StyledGrid item xs={12}>
                      <StyledTypography
                        component="a"
                        href={`tel:+${t("Header.PhoneNumber")}`}
                        variant="body1">
                        {t("Header.PhoneNumber")}
                      </StyledTypography>
                    </StyledGrid>
                    <StyledGrid item xs={12}>
                      <StyledTypography variant="body1">
                        {t("Header.CustomerService")}
                      </StyledTypography>
                    </StyledGrid>
                  </StyledGrid>
                </Hidden>
                {loggedIn && isStipendUser && (
                  <Hidden xsDown>
                    <StyledGrid container className="header-stipend-contracts">
                      {GetStipendSelectorLink()}
                    </StyledGrid>
                  </Hidden>
                )}
                {loggedIn ? (
                  <StyledGrid item>
                    <StyledButton
                      ref={myAccountElRef}
                      variant="text"
                      color="secondary"
                      className="header-actionsButton"
                      style={{ paddingRight: "8px" }}
                      onClick={handleMyAccountClick}
                      testId="heaher-action-account">
                      {isTablet ? (
                        <StyledHeaderActions>
                          <PersonIcon />
                        </StyledHeaderActions>
                      ) : (
                        <>
                          <StyledHeaderActions>
                            <PersonIcon />
                            <StyledTypography variant="body1" component="p">
                              {t("Header.Actions.Account")}
                            </StyledTypography>
                          </StyledHeaderActions>
                        </>
                      )}
                    </StyledButton>
                    <StyledAccountPopper
                      id={myAccountPopperId}
                      open={myAccountPopperOpen}
                      anchorEl={myAccountElRef.current}
                      onClose={handleMyAccountPopperClose}
                      placement={"bottom-end"}
                      modifiers={{
                        flip: {
                          enabled: false,
                        },
                        preventOverflow: {
                          enabled: true,
                          boundariesElement: "scrollParent",
                        },
                        hide: {
                          enabled: true,
                        },
                      }}
                      className="account-popper">
                      <ClickAwayListener
                        onClickAway={handleMyAccountPopperClose}>
                        <StyledPaper className="horizontal-padding-2">
                          <StyledTypography variant="body1" component="div">
                            <AccountPopperContent
                              handleClose={handleMyAccountPopperClose}
                              handleOrgChange={handleOrgChange}
                              handleContractChange={handleContractChange}
                              handleLogout={handleLogout}
                              stipendEspot={stipendEspot}
                              isB2B={isB2B}
                              userName={{ firstName, lastName }}
                            />
                          </StyledTypography>
                        </StyledPaper>
                      </ClickAwayListener>
                    </StyledAccountPopper>
                  </StyledGrid>
                ) : (
                  <StyledGrid item>
                    <StyledButton
                      className="header-actionsButton"
                      variant="text"
                      component={Link}
                      to={ROUTES.SIGNIN}
                      color="secondary"
                      testId="sign-in-button">
                      <StyledHeaderActions>
                        <PersonIcon />
                        {isDesktop && (
                          <StyledTypography variant="body1">
                            {t("Header.Actions.SignIn")}
                          </StyledTypography>
                        )}
                      </StyledHeaderActions>
                    </StyledButton>
                  </StyledGrid>
                )}
                {/*<StyledGrid item>
                  <LanguageToggle
                    id={languageTogglePopperId}
                    open={languageTogglePopperOpen}
                    handleClick={handleLanguageToggleClick}
                    handleClose={handleLanguageTogglePopperClose}
                    ref={languageToggleElRef}
                  />
                </StyledGrid>*/}

                {isShoppingEnabled && (
                  <StyledGrid item>
                    <MiniCart
                      id={miniCartPopperId}
                      isStipendUser={isStipendUser}
                      isLoggedIn={loggedIn}
                      open={miniCartPopperOpen}
                      handleClick={handleMiniCartClick}
                      handleClose={handleMiniCartPopperClose}
                      handleClickStipendDialogOpen={
                        handleClickStipendDialogOpen
                      }
                      ref={miniCartElRef}
                    />
                  </StyledGrid>
                )}
                {sellerConfig?.showSellerList ? (
                  <MarketplacePopper {...{ sellerConfig }} />
                ) : null}
              </StyledGrid>
            </StyledGrid>
          </StyledGrid>
        </StyledContainer>
        {loggedIn && isStipendUser && (
          <Hidden smUp>
            <StyledContainer className="header-stipend-contracts-mobile">
              {GetStipendSelectorLink()}
            </StyledContainer>
          </Hidden>
        )}
        <Hidden mdUp>
          <StyledContainer className="mobile-search-bar">
            <SearchBar
              showSearchBar={showSearchBar}
              closeSearchBar={turnOffSearchBar}
              openSearchBar={turnOnSearchBar}
              selectedContractId={selectedContractId}
            />
          </StyledContainer>
        </Hidden>

        <Hidden smDown>
          <ExpandedMenu pages={topCategories}>
            <StyledGrid
              item
              className="desktop-search-bar"
              data-testid="search-bar-desktop-largetablet-element">
              <SearchBar
                showSearchBar={showSearchBar}
                closeSearchBar={turnOffSearchBar}
                openSearchBar={turnOnSearchBar}
                selectedContractId={selectedContractId}
              />
            </StyledGrid>
          </ExpandedMenu>
        </Hidden>

        <StyledSwipeableDrawer
          anchor="left"
          open={open}
          onClose={turnOffOpen}
          onOpen={turnOnOpen}
          className="header-menu"
          data-testid="header-menu-drawer-element">
          <StyledContainer>
            <StyledGrid
              container
              className={"menu-container " + (open ? "open" : "")}>
              <IconButton
                aria-label="close menu"
                className="close"
                onClick={turnOffOpen}>
                <CloseIcon />
              </IconButton>

              <MegaMenu
                menutitle={t("MegaMenu.Title")}
                pages={topCategories}
                closeMegaMenu={turnOffOpen}
              />

              <StyledTypography
                variant="body1"
                component="ul"
                className="content-menu">
                <li>
                  <Link to={ROUTES.CONTACT_US}>Contact &amp; Help</Link>
                </li>
              </StyledTypography>
            </StyledGrid>
          </StyledContainer>
        </StyledSwipeableDrawer>
      </StyledHeader>
    </>
  );
};

export { Header };
