/* Content menu for DiVal header */
import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  StyledGrid,
  StyledTypography,
} from "@hcl-commerce-store-sdk/react-component";
import { CONTACT_US } from "../../constants/routes";

const ContentMenu = (props: any) => {
  const { t } = useTranslation();

  return (
    <StyledGrid
      container
      spacing={4}
      justifyContent="flex-end"
      className="header-content-menu">
      <StyledGrid item>
        <StyledTypography variant="body2" component={Link} to={CONTACT_US}>
          {t("ContentMenu.Contact")}
        </StyledTypography>
      </StyledGrid>
    </StyledGrid>
  );
};

export default ContentMenu;
