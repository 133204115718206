/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
//Custom libraries
import { CART, CHECKOUT, IP_ORDER_DETAILS } from "../../constants/routes";
import { MINICART_CONFIGS } from "../../configs/order";
import { PRIVATE_ORDER_TYPE } from "../../constants/order";
//Redux
import {
  numItemsSelector,
  cartSelector,
  orderItemsSelector,
} from "../../redux/selectors/order";
import { forUserIdSelector, userIdSelector } from "../../redux/selectors/user";
//UI
import { ClickAwayListener } from "@material-ui/core";
import { StyledMiniCartContent } from "../StyledUI";
import { useSite } from "../../_foundation/hooks/useSite";

interface MiniCartPopperContentProps {
  handleClose: (e?: any) => void;
  handleClickStipendDialogOpen: (e?: any) => void;
  isLoggedIn: boolean;
  isStipendUser: boolean;
}

/**
 * MiniCartPopperContent component
 * displays mini cart popper contents
 * @param props
 */
const MiniCartPopperContent: React.FC<MiniCartPopperContentProps> = (
  props: any
) => {
  const {
    handleClose,
    handleClickStipendDialogOpen,
    isLoggedIn,
    isStipendUser,
  } = props;
  const numItems = useSelector(numItemsSelector);
  const cart = useSelector(cartSelector);
  const orderItems = useSelector(orderItemsSelector);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mySite } = useSite();
  const isB2B = mySite?.isB2B;
  const forUserId = useSelector(forUserIdSelector);
  const uId = useSelector(userIdSelector);
  const userId = forUserId ?? uId;

  const handleCartOnClick = () => {
    if (!isB2B) {
      handleClose();
      navigate(CART);
    } else {
      if (!cart || userId === cart.buyerId) {
        handleClose();
        navigate(CART);
      } else {
        handleClose();
        navigate(`${IP_ORDER_DETAILS}/${cart.orderId}`);
      }
    }
  };

  const handleCheckoutOnClick = () => {
    handleClose();
    navigate(CHECKOUT);
  };

  const initOrderTotalSummary = () => {
    let subtotal: number | null = null;
    let subtotalCurrency: string = "";
    let hasStipend: boolean = false;
    let stipendShowFullPrice: boolean | null = null;
    let stipendSubtotal: number | null = null;
    if (cart) {
      try {
        if (cart.orderExtendAttribute) {
          cart.orderExtendAttribute.forEach(function (attr) {
            if (attr.attributeName === "STIPEND_SHOW_FULL_PRICE") {
              if (attr.attributeValue === "true") {
                stipendShowFullPrice = true;
              } else if (attr.attributeValue === "false") {
                stipendShowFullPrice = false;
              }
            } else if (attr.attributeName === "STIPEND_DISPLAY_SUBTOTAL") {
              stipendSubtotal = parseFloat(attr.attributeValue);
            }
          });
        }

        if (
          stipendShowFullPrice !== null &&
          typeof stipendSubtotal === "number"
        ) {
          hasStipend = true;
          subtotal = stipendSubtotal;
        } else {
          subtotal = cart.totalProductPrice
            ? parseFloat(cart.totalProductPrice)
            : null;
        }
        subtotalCurrency = cart.totalProductPriceCurrency;
      } catch (e) {
        console.log("Could not parse cart totals");
      }
    }
    return {
      subtotal,
      subtotalCurrency,
      hasStipend,
      stipendShowFullPrice,
    };
  };

  const { subtotal, subtotalCurrency, hasStipend, stipendShowFullPrice } =
    useMemo(initOrderTotalSummary, [cart]);
  const miniCartItems = useMemo(
    () => orderItems.slice(MINICART_CONFIGS.maxItemsToShow * -1).reverse(),
    [orderItems]
  );
  return (
    <ClickAwayListener onClickAway={handleClose}>
      <StyledMiniCartContent
        title={
          !isB2B
            ? t("MiniCart.Title")
            : !cart
            ? t("MiniCart.MyOrder")
            : cart.orderDescription
            ? cart.orderDescription
            : t("MiniCart.Title")
        }
        orderType={
          !cart
            ? t("MiniCart.PrivateOrder")
            : cart.orderTypeCode === PRIVATE_ORDER_TYPE
            ? t("MiniCart.PrivateOrder")
            : userId === cart.buyerId
            ? t("MiniCart.SharedOrderAdmin")
            : t("MiniCart.SharedOrderConributor")
        }
        orderItems={miniCartItems}
        subtotalLabel={t("MiniCart.Subtotal", { count: numItems })}
        subtotal={subtotal}
        subtotalCurrency={subtotalCurrency}
        hasStipend={hasStipend}
        stipendShowFullPrice={stipendShowFullPrice}
        emptyCartMsg={t("MiniCart.Empty")}
        cartLinkLabel={
          !isB2B
            ? t("MiniCart.Actions.Cart")
            : !cart
            ? t("MiniCart.Actions.Cart")
            : userId === cart.buyerId
            ? t("MiniCart.Actions.Cart")
            : t("MiniCart.Actions.ViewOrderDetails")
        }
        checkoutLinkLabel={t("MiniCart.Actions.CheckOut")}
        handleCartOnClick={handleCartOnClick}
        handleCheckoutOnClick={handleCheckoutOnClick}
        handleClose={handleClose}
        isOrderOwner={!cart ? true : userId === cart.buyerId}
        isB2B={isB2B}
        handleClickStipendDialogOpen={handleClickStipendDialogOpen}
        isStipendUser={isStipendUser}
        isLoggedIn={isLoggedIn}
      />
    </ClickAwayListener>
  );
};

export default MiniCartPopperContent;
