/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { useEffect, Fragment, useRef } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Axios, { Canceler } from "axios";
import getDisplayName from "react-display-name";
//Redux
import { forUserIdSelector } from "../../../redux/selectors/user";
//Custom libraries
import { EMPTY_STRING } from "../../../constants/common";
import { localStorageUtil } from "../../../_foundation/utils/storageUtil";
import selectorImage from "./stipendContractimages/stipend selector.jpg";
//UI
import {
  StyledDialog,
  StyledDialogTitle,
  StyledDialogContent,
  StyledDialogActions,
  StyledButton,
  StyledFormControl,
  StyledRadioGroup,
  StyledFormControlLabel,
  StyledRadio,
  StyledTypography,
  StyledGrid,
} from "@hcl-commerce-store-sdk/react-component";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

interface StipendContractProps {
  stipendContracts: any;
  toggleStipendOption: (v?: any) => void;
  stipendOptionValue: string;
  stipendDefaultContractId: number;
  defaultNonStipendName: string;
  defaultNonStipendDescription: string;
  stipendDialogOpen: boolean;
  setStipendDialogOpen: any;
  handleClose: any;
}

const StipendContractsDialog = React.forwardRef<
  HTMLButtonElement | null,
  StipendContractProps
>((props: any, ref: any) => {
  const {
    stipendContracts,
    toggleStipendOption,
    stipendOptionValue,
    stipendDefaultContractId,
    defaultNonStipendName,
    defaultNonStipendDescription,
    stipendDialogOpen,
    setStipendDialogOpen,
    handleClose,
  } = props;
  const widgetName = getDisplayName(StipendContractsDialog);

  const theme = useTheme();
  const { t } = useTranslation();

  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  // const stipendContractorElRef = useRef<HTMLButtonElement>(null);

  const CancelToken = Axios.CancelToken;
  const cancels: Canceler[] = [];

  const payloadBase: any = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };

  const handleSuccess = () => {
    handleClose();
  };

  const handleSubmit = (props: any) => {
    props.preventDefault();
    handleSuccess();
  };

  const canContinue = () => {
    if (
      stipendOptionValue?.trim() !== EMPTY_STRING ||
      localStorageUtil.get("selectedStipend") ||
      (!stipendContracts && !stipendDefaultContractId)
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    return () => {
      cancels.forEach((cancel) => cancel());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!localStorageUtil.get("selectedStipend")) {
      setStipendDialogOpen(true);
    } else {
      setStipendDialogOpen(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDefaultContractStipendOption = () => {
    const nonStipendName = defaultNonStipendName
      ? defaultNonStipendName
      : "Credit Card";

    const nonStipendDescription = defaultNonStipendDescription
      ? defaultNonStipendDescription
      : "View DiVal's Full Catalog of Footwear & Accessories!\n*Please note all items may not meet your company or department specific requirements.";

    return (
      <div>
        <StyledFormControlLabel
          value={stipendDefaultContractId}
          name={nonStipendName}
          control={<StyledRadio style={{ color: "red" }} />}
          checked={nonStipendName === localStorageUtil.get("selectedStipend")}
          style={{ margin: "-11px", marginTop: "11px" }}
          label={
            <StyledTypography variant="h6">{nonStipendName}</StyledTypography>
          }
        />
        <StyledTypography
          variant="body2"
          style={{
            marginLeft: "2rem",
            fontSize: "0.925rem",
            whiteSpace: "pre-line",
          }}>
          {nonStipendDescription}
        </StyledTypography>
      </div>
    );
  };

  return (
    <div>
      <StyledDialog
        open={stipendDialogOpen}
        onClose={handleClose}
        aria-labelledby="change-stipend-dialog">
        <StyledDialogTitle
          style={{ margin: "auto" }}
          title={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <div style={{ textAlign: "center" }}>
                <StyledTypography variant="h4">
                  Stipend Selector:
                </StyledTypography>
                <StyledTypography
                  variant="subtitle2"
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    width: "76%",
                    color: "#f44336",
                  }}>
                  Please select 1 to start, you can change your stipend at any
                  time in the upper right-hand corner.
                </StyledTypography>
              </div>
              <div>
                <img
                  src={selectorImage}
                  alt=""
                  style={{
                    width: isDesktop ? "70px" : "60px",
                    height: isDesktop ? "70px" : "60px",
                    marginLeft: isDesktop ? "-70px" : "-20px",
                  }}
                />
              </div>
            </div>
          }
          onClickHandler={handleClose}
        />

        <StyledDialogContent>
          <form name="stipendContractForm" noValidate onSubmit={handleSubmit}>
            {stipendContracts &&
            stipendContracts.stipends &&
            stipendContracts.stipends.length > 0 ? (
              <StyledGrid item xs={12} style={{ padding: "12px" }}>
                <StyledFormControl variant="outlined" component="fieldset">
                  <StyledRadioGroup
                    name={localStorageUtil.get("selectedStipend")}
                    value={localStorageUtil.get("selectedStipendContractId")}
                    onChange={(event) => toggleStipendOption(event)}>
                    {stipendContracts.stipends.map((stipend: any) => (
                      <Fragment key={stipend.stipendId}>
                        <StyledFormControlLabel
                          value={stipend.contractId}
                          name={stipend.stipendName}
                          control={
                            <StyledRadio
                              style={{
                                color: "#f44336",
                              }}
                            />
                          }
                          checked={
                            stipend.stipendName ===
                            localStorageUtil.get("selectedStipend")
                          }
                          style={{ margin: "-11px", marginTop: "11px" }}
                          label={
                            <div>
                              <StyledTypography variant="h6">
                                Stipend: {stipend.stipendName}
                              </StyledTypography>
                            </div>
                          }
                          disabled={false}
                        />
                        <div style={{ marginLeft: "2rem" }}>
                          <StyledTypography
                            variant="body2"
                            style={{
                              fontSize: "0.925rem",
                              whiteSpace: "pre-line",
                            }}>
                            {" "}
                            {stipend.stipendDesc}{" "}
                          </StyledTypography>
                          <StyledTypography
                            variant="body2"
                            style={{
                              fontStyle: "italic",
                              fontSize: "0.925rem",
                            }}>
                            {" "}
                            {"Eligibility"}: {stipend.eligibilityDescription}
                          </StyledTypography>
                          {stipend.nextResetDate && (
                            <StyledTypography
                              variant="body2"
                              style={{
                                fontStyle: "italic",
                                fontSize: "0.925rem",
                                textTransform: "uppercase",
                              }}>
                              {`-Your stipend will renew on ${stipend.nextResetDate}`}
                            </StyledTypography>
                          )}
                        </div>
                      </Fragment>
                    ))}
                    {stipendDefaultContractId &&
                      getDefaultContractStipendOption()}
                  </StyledRadioGroup>
                </StyledFormControl>
              </StyledGrid>
            ) : (
              <StyledGrid item xs={12} style={{ padding: "12px" }}>
                <StyledTypography variant="body1">
                  {
                    "You have no active stipends. Please contact support for assistance."
                  }
                </StyledTypography>
                {stipendDefaultContractId && (
                  <StyledFormControl variant="outlined" component="fieldset">
                    <StyledRadioGroup
                      name={localStorageUtil.get("selectedStipend")}
                      value={localStorageUtil.get("selectedStipendContractId")}
                      onChange={(event) => toggleStipendOption(event)}>
                      {getDefaultContractStipendOption()}
                    </StyledRadioGroup>
                  </StyledFormControl>
                )}
              </StyledGrid>
            )}
            {
              <StyledDialogActions style={{ padding: "12px" }}>
                <StyledButton
                  testId="stipend-selector-dialog-submit"
                  color="primary"
                  type="submit"
                  disabled={!canContinue()}>
                  {t("Header.Actions.DoneLabel")}
                </StyledButton>
              </StyledDialogActions>
            }
          </form>
        </StyledDialogContent>
      </StyledDialog>
    </div>
  );
});

export { StipendContractsDialog };
