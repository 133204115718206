/* For prerendering an espot that will be passed as a child component.
   Currently using this in the header to render the espot displayed in the account popper.
   See ContentRecommendationLayout.tsx for the original logic.
 */

import React from "react";
import HTMLReactParser, {
  DOMNode,
  Element,
  domToReact,
} from "html-react-parser";
import { Link } from "react-router-dom";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { CONTENT_FORMAT_ID, HCL_Dx_PREFIX } from "../../constants/marketing";
import { StyledProgressPlaceholder } from "@hcl-commerce-store-sdk/react-component";
import { CLICK_EVENT_TRIGGERED_ACTION } from "../../redux/actions/marketingEvent";
import { commonUtil } from "@hcl-commerce-store-sdk/utils";

const contentEspotUtil = {
  processMarketingSpotData: (
    eSpotRoot: any,
    title: any,
    substitutionParams: any,
    payloadBase: any,
    props: any,
    dispatch: any
  ) => {
    const d: any[] = [];
    const eSpotData = eSpotRoot.baseMarketingSpotActivityData || [];

    const performClick = (
      event: React.MouseEvent<HTMLElement, MouseEvent>,
      { eSpotRoot, eSpotDesc }: any
    ) => {
      event.preventDefault();
      dispatch(
        CLICK_EVENT_TRIGGERED_ACTION({ eSpotRoot, eSpotDesc, ...payloadBase })
      );
      const linkAction = commonUtil.parseContentUrl(eSpotDesc.contentUrl || "");
      if (linkAction) {
        switch (linkAction["action"]) {
          case "navigate":
            if (
              linkAction.toLink.length > 0 &&
              linkAction.toLink !== props.history.location.pathname
            ) {
              props.history.push(linkAction.toLink);
            }
            break;
        }
      }
    };

    for (const eSpotDesc of eSpotData) {
      // Define the component using Component decorator.
      const currentTemplate: any = {
        id: null,
        template: null,
        isDxContent: false,
      };
      const desc = eSpotDesc.marketingContentDescription;
      const assetSrc =
        eSpotDesc.attachmentAsset && eSpotDesc.attachmentAsset[0]
          ? eSpotDesc.attachmentAsset[0]["attachmentAssetPath"]
          : "";
      const assetName =
        eSpotDesc.attachmentDescription && eSpotDesc.attachmentDescription[0]
          ? eSpotDesc.attachmentDescription[0]["attachmentName"]
          : "";

      const replace = (node: DOMNode): any => {
        if (
          node instanceof Element &&
          node.type &&
          node.type === "tag" &&
          node.name &&
          node.name === "a"
        ) {
          return (
            <Link
              key={`a_${eSpotDesc.contentId}}`}
              to={eSpotDesc.contentUrl}
              onClick={(event) =>
                performClick(event, { eSpotRoot, eSpotDesc })
              }>
              {node.children && domToReact(node.children)}
            </Link>
          );
        } else {
          return;
        }
      };

      if (eSpotDesc.contentFormatId === CONTENT_FORMAT_ID.EXTERNAL) {
        currentTemplate.isDxContent = true;
        //dx content using url field to save content reference.
        if (
          eSpotDesc.contentUrl &&
          eSpotDesc.contentUrl.startsWith(HCL_Dx_PREFIX)
        ) {
          currentTemplate.template = eSpotDesc.contentUrl.substr(
            HCL_Dx_PREFIX.length
          );
        } else {
          currentTemplate.template = eSpotDesc.contentUrl || "";
        }
      } else if (
        desc &&
        desc[0] &&
        desc[0]["marketingText"] &&
        desc[0]["marketingText"].length > 0
      ) {
        let marketingText = desc[0].marketingText.trim();
        substitutionParams?.forEach((param) => {
          marketingText = marketingText.replace(
            Object.keys(param)[0],
            Object.values(param)[0]
          );
        });

        //each template text suppose to only have one <a> tag
        currentTemplate.template = HTMLReactParser(marketingText, {
          replace,
        });
      } else {
        currentTemplate.template = (
          <div>
            {eSpotDesc.contentUrl ? (
              <Link
                to={eSpotDesc.contentUrl}
                onClick={(event) =>
                  performClick(event, { eSpotRoot, eSpotDesc })
                }>
                <LazyLoadComponent
                  placeholder={
                    <StyledProgressPlaceholder className="vertical-padding-20" />
                  }>
                  <img alt={assetName} src={assetSrc}></img>
                </LazyLoadComponent>
              </Link>
            ) : (
              <LazyLoadComponent
                placeholder={
                  <StyledProgressPlaceholder className="vertical-padding-20" />
                }>
                <img alt={assetName} src={assetSrc}></img>
              </LazyLoadComponent>
            )}
          </div>
        );
      }
      currentTemplate.id = eSpotDesc.contentId;

      d.push(currentTemplate);
    }
    return d;
  },
};

export default contentEspotUtil;
