/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { paramCase } from "change-case";
//Foundation libraries
import { useSite } from "../../_foundation/hooks/useSite";
//Custom libraries
import { footerConfig } from "./footerConstant";
import ContentRecommendationWidget from "../commerce-widgets/content-recommendation-widget";
import { SessionErrorDialog } from "../widgets/session-error-modal";
import ConfirmationDialog from "../widgets/confirmation-dialog/ConfirmationDialog";
import {
  CONTACT_US,
  TERMS_AND_CONDITIONS,
  PRIVACY_POLICY,
} from "../../constants/routes";
//UI
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import {
  StyledGrid,
  StyledContainer,
  StyledTypography,
  StyledFooter,
} from "@hcl-commerce-store-sdk/react-component";

function Footer(props: any) {
  const { t } = useTranslation();
  const { mySite } = useSite();
  const Logo = useCallback(() => {
    return mySite != null ? (
      <div className="footer-logo">
        <ContentRecommendationWidget
          {...{
            widget: {
              id: `footer-${paramCase(footerConfig.espot.eSpotName)}`,
              widgetName: "content-recommendation-widget",
              name: footerConfig.espot.eSpotName,
              properties: {
                emsName: footerConfig.espot.eSpotName,
              },
            },
            page: { name: "" },
          }}></ContentRecommendationWidget>
      </div>
    ) : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, mySite]);

  const CustomerSupportLinks = () => {
    return (
      <div className="footer-nav">
        <StyledTypography variant="body1" className="footer-menu-header">
          {t("Footer.CustomerSupport.Label")}
        </StyledTypography>
        <StyledTypography variant="body1">
          <Link key="contact-us" to={CONTACT_US}>
            {t("Footer.CustomerSupport.ContactUs")}
          </Link>
        </StyledTypography>
        <StyledTypography variant="body1">
          <Link key="terms-and-conditions" to={TERMS_AND_CONDITIONS}>
            {t("Footer.CustomerSupport.TermsAndConditions")}
          </Link>
        </StyledTypography>
      </div>
    );
  };

  const CompanyInformationLinks = () => {
    return (
      <div className="footer-nav">
        <StyledTypography variant="body1" className="footer-menu-header">
          {t("Footer.CompanyInformation.Label")}
        </StyledTypography>
        <StyledTypography variant="body1">
          <a
            href="https://www.divalsafety.com/"
            target="_blank"
            rel="noopener noreferrer">
            {t("Footer.CompanyInformation.CorporateSite")}
          </a>
        </StyledTypography>
        <StyledTypography variant="body1">
          <Link key="privacy-policy" to={PRIVACY_POLICY}>
            {t("Footer.CompanyInformation.PrivacyPolicy")}
          </Link>
        </StyledTypography>
      </div>
    );
  };

  const Copyright = () => {
    const copyright_year = new Date().getFullYear();
    return (
      <div className="copyright">
        <StyledTypography variant="caption">
          {t("Footer.Copyright", { copyright_year })}
        </StyledTypography>
      </div>
    );
  };

  return (
    <>
      <StyledFooter>
        <StyledContainer>
          <StyledGrid container spacing={2} className="footer-top">
            <StyledGrid item className="logo">
              <div>
                <Logo />
                <StyledTypography variant="body1">
                  {t("Footer.Description")}
                </StyledTypography>
              </div>
            </StyledGrid>
            <StyledGrid item className="links">
              <StyledGrid container spacing={2}>
                <StyledGrid item>
                  <CompanyInformationLinks />
                </StyledGrid>
                <StyledGrid item>
                  <CustomerSupportLinks />
                </StyledGrid>
              </StyledGrid>
            </StyledGrid>
            <StyledGrid item className="social-media">
              <div>
                <div>
                  <StyledTypography variant="body1">
                    {t("Footer.CustomerService")}
                  </StyledTypography>
                </div>
                <div>
                  <StyledTypography
                    variant="body1"
                    component="a"
                    href={`tel:+${t("Footer.PhoneNumber")}`}>
                    {t("Footer.PhoneNumber")}
                  </StyledTypography>
                </div>
                <a
                  href="https://www.facebook.com/DiValSafety"
                  aria-label={t("SocialMedia.Facebook")}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-social-link">
                  <FacebookIcon />
                </a>
                <a
                  href="https://www.instagram.com/divalsafety"
                  aria-label={t("SocialMedia.Instagram")}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-social-link">
                  <InstagramIcon />
                </a>
                <a
                  href="https://twitter.com/DiValSafety"
                  aria-label={t("SocialMedia.Twitter")}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-social-link">
                  <TwitterIcon />
                </a>
                <a
                  href="https://www.linkedin.com/company/dival-safety"
                  aria-label={t("SocialMedia.LinkedIn")}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-social-link">
                  <LinkedInIcon />
                </a>
              </div>
            </StyledGrid>
          </StyledGrid>
          <StyledGrid container className="footer-bottom">
            <StyledGrid item xs={12}>
              <Copyright />
            </StyledGrid>
          </StyledGrid>
        </StyledContainer>
      </StyledFooter>
      <SessionErrorDialog />
      <ConfirmationDialog />
    </>
  );
}

export default React.memo(Footer);
