/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { call, put } from "redux-saga/effects";
//Foundation libraries
import productsService from "../../../_foundation/apis/search/products.service";
import stipendPriceService from "../../../_foundation/apis/transaction/_dival/stipendPrice.service";
import { localStorageUtil } from "../../../_foundation/utils/storageUtil";

//Redux
import * as ACTIONS from "../../action-types/catalog";

/**
 * Saga worker to invoke get product list API
 */
export function* fetchProductList(action: any) {
  try {
    const payload = action.payload;
    const response = yield call(
      productsService.findProductsUsingGET,
      payload.parameters
    );
    try {
      if (response?.data?.contents) {
        let idList;
        response?.data?.contents.forEach(function (item, index) {
          if (index === 0) {
            idList = item.id;
          } else {
            idList = idList + "," + item.id;
          }
        });
        const stipendResponse = yield call(
          stipendPriceService.getListByCatentryIds,
          {
            catentryIds: idList,
            stipendId: localStorageUtil.get("selectedStipendId")
              ? localStorageUtil.get("selectedStipendId")
              : 0,
            ...payload.parameters,
          }
        );
        if (stipendResponse?.data?.stipendPrices) {
          response?.data?.contents.forEach(function (item, index) {
            const itemStipend = stipendResponse.data.stipendPrices.filter(
              (i) => i.catentryId === item.id
            );
            response.data.contents[index].stipendDisplayPrice =
              itemStipend[0].displayPrice;
            response.data.contents[index].showStipend =
              itemStipend[0].showStipend;
          });
        }
      }
    } catch (e) {
      console.log("Unable to get stipend prices", e);
    }
    yield put({
      type: ACTIONS.PRODUCT_LIST_GET_SUCCESS,
      response: response.data,
      payload: payload,
    });
  } catch (error) {
    yield put({ type: ACTIONS.PRODUCT_LIST_GET_ERROR, error });
  }
}

/**
 * Saga worker to invoke get product list API for PDP
 */
export function* fetchProductListForPDP(action: any) {
  try {
    const payload = action.payload;
    const response = yield call(
      productsService.findProductsUsingGET,
      payload.parameters
    );
    yield put({
      type: ACTIONS.PRODUCT_LIST_FOR_PDP_GET_SUCCESS,
      response: response.data,
      payload: payload,
    });
  } catch (error) {
    yield put({ type: ACTIONS.PRODUCT_LIST_FOR_PDP_GET_ERROR, error });
  }
}

export function* fetchProductListDetails(action: any) {
  try {
    const payload = action.payload;
    const params = payload.parameters;
    const r = yield call(productsService.findProductsUsingGET, params);
    const response = r.data;
    yield put({ type: ACTIONS.PRODUCT_LIST_DEETS_S, response, payload });
  } catch (error) {
    yield put({ type: ACTIONS.PRODUCT_LIST_DEETS_F, error });
  }
}
